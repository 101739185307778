import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import About from '../../templates/About';

const AboutPage = () => (
  <Layout>
    <About />
  </Layout>
);

export default AboutPage;

export const Head = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  return <SEO title={`${t('about')} - Giedeé Informatyka`} />;
};
