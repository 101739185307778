import styled from 'styled-components';
import media from 'styled-media-query';

export const DescriptionContainer = styled.div`
  /* width: 100%; */
  padding: 10px;
  user-select: none;
`;

export const Description = styled.p`
  font-family: var(--font-open);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  color: var(--primary-color);
  white-space: pre-line;
  ${media.greaterThan('medium')`
    font-size: 16px;
    margin-top:20px
  `}
`;
