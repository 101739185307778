import styled from 'styled-components';
import media from 'styled-media-query';

export const QuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  min-height: 120px;
  ${media.greaterThan('medium')`
    padding: 30px;
    margin-top:50px;
  `}
  ${media.greaterThan('large')`
    margin-top:10px;
  `}
`;
export const Quote = styled.div`
  display: block;
  color: var(--primary-color);
`;

export const Author = styled.div`
  display: block;
  color: var(--primary-color);
  text-align: right;
  align-self: flex-end;
`;

export const Quotation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  min-height: 90px;
  ${media.greaterThan('medium')`
   font-size:16px;
  `}
`;

export const QuotationMark = styled.div`
  display: block;
  width: 40px;
  color: var(--primary-color);
  align-self: ${({ align }) => align};
  margin: 0 10px;
  ${media.greaterThan('medium')`
    width: 30px;
    margin: 0 30px;
  `}
`;
