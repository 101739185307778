import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${media.greaterThan('large')`
    max-width:1170px;
    margin-bottom:120px;
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0 0;
  position: relative;
`;
