import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 120px;
  left: 0;
  opacity: 0.6;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0; // calc(20vw);
  width: 50%;
  height: 100%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  .region {
    &:hover {
      fill: var(--primary-color);
      stroke: var(--thirdy-color);
    }
  }
  /* ${media.greaterThan('medium')`
        width: 70%;
    `}
  ${media.greaterThan('large')`
        width: 50%;
    `} */
`;
