import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { gsap, Power1 } from 'gsap';

import * as S from './styled';

import TitlePage from '../../components/TitlePage';
import AboutPomeranianMap from '../../components/AboutPomeranianMap';
import AboutDescription from '../../components/AboutDescription';
import AboutQuotation from '../../components/AboutQuotation';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import PageImage from '../../components/PageImage';

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gsap.from('.photo', {
      delay: 0.8,
      duration: 0.8,
      autoAlpha: 0,
      rotate: 90,
      y: 100,
      ease: Power1.easeInOut,
    });
    gsap.from('.about-description', {
      delay: 1.2,
      duration: 0.8,
      autoAlpha: 0,
      y: 100,
      ease: Power1.easeInOut,
    });
    gsap.from('.pomeranian-map', {
      delay: 1.6,
      autoAlpha: 0,
      duration: 1.5,
    });
  }, []);

  return (
    <WrapperPage id="about">
      <PageImage>
        <StaticImage
          src="../../assets/images/homeoffice.jpg"
          alt="Home office"
          style={{ width: '100%', height: '100%', opacity: 0.2 }}
          layout="fullWidth"
          objectPosition="50% 75%"
          quality={85}
          imgStyle={{
            filter: 'grayscale()',
          }}
        />
      </PageImage>
      <WrapperSection>
        <S.Container>
          <TitlePage
            text={t('about')}
            subtitle={`Giedeé ${t('andCompany')}`}
          />
          <S.Content>
            <AboutDescription />
          </S.Content>
          <AboutQuotation />
        </S.Container>
        <AboutPomeranianMap />
      </WrapperSection>
    </WrapperPage>
  );
};

export default About;
