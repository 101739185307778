import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';
import { TextPlugin } from 'gsap/TextPlugin';

import { QuoteLeft } from '@styled-icons/boxicons-solid/QuoteLeft';
import { QuoteRight } from '@styled-icons/boxicons-solid/QuoteRight';

import * as S from './styled';

const AboutQuotation = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const quotes = [
    {
      quote: `${t('quoteAlbertEinstain')}`,
      author: 'Albert Einstein',
    },
    {
      quote: `${t('quoteMarieVonEbnerEschenbach')}`,
      author: 'Marie von Ebner-Eschenbach',
    },
    {
      quote: `${t('quoteBrianTracey')}`,
      author: 'Brian Tracy',
    },
    {
      quote: `${t('quoteThomasEdison')}`,
      author: 'Thomas Edison',
    },
    {
      quote: `${t('quoteNapoleonHill')}`,
      author: 'Napleon Hill',
    },
  ];
  const [quoteIndex, setQuoteIndex] = useState(0);
  const handleNewQuote = () => {
    const newIndex =
      quoteIndex === quotes.length - 1 ? 0 : quoteIndex + 1;
    setQuoteIndex(newIndex);
  };

  let quotation = useRef(null);
  let author = useRef(null);
  let left = useRef(null);
  let right = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(TextPlugin);
    const timeline = gsap.timeline({
      onComplete: handleNewQuote,
    });
    timeline
      .set(quotation, {
        text: { value: quotes[quoteIndex].quote },
        opacity: 0,
      })
      .set(author, { text: 'Author', opacity: 0 })
      .fromTo(
        quotation,
        {
          opacity: 0,
          ease: 'power1.inOut',
          scale: 0.8,
          text: { value: quotes[quoteIndex].quote },
        },
        {
          opacity: 1,
          scale: 1,
          ease: 'power1.inOut',
          clearProps: 'scale',
          duration: 1.5,
          delay: 0.5,
        }
      )
      .to(author, {
        duration: 1.5,
        text: { value: `- ${quotes[quoteIndex].author}` },
        ease: 'none',
        opacity: 1,
        delay: 1,
      })
      .fromTo(
        left,
        {
          opacity: 0,
          scale: 0.5,
        },
        { opacity: 1, scale: 1, duration: 1 }
      )
      .fromTo(
        right,
        {
          opacity: 0,
          scale: 0.5,
        },
        { opacity: 1, scale: 1, duration: 1 }
      )
      .to({}, { duration: 2 })
      .play();

    return () => timeline.kill();
  }, [quoteIndex]);

  return (
    <S.QuoteContainer>
      <S.QuotationMark
        align="flex-end"
        ref={(el) => {
          left = el;
        }}
      >
        <QuoteLeft />
      </S.QuotationMark>
      <S.Quotation>
        <S.Quote
          ref={(el) => {
            quotation = el;
          }}
        />
        <S.Author
          ref={(el) => {
            author = el;
          }}
        />
      </S.Quotation>
      <S.QuotationMark
        align="flex-start"
        ref={(el) => {
          right = el;
        }}
      >
        <QuoteRight />
      </S.QuotationMark>
    </S.QuoteContainer>
  );
};

export default AboutQuotation;
