import React from 'react';
// import { useTranslation } from 'react-i18next';

import * as S from './styled';

const AboutDescription = () => (
  // const { t } = useTranslation('translation', {
  //   useSuspense: false,
  // });

  <S.DescriptionContainer className="about-description">
    <S.Description>
      Ciągle te kompy naprawiam,
      <br /> Jak padł Ci soft ja to ogarniam,
      <br /> Systemy, programy wciąż instaluje,
      <br /> No i też sieci konfiguruje,
      <br /> W necie wciąż grzebie bez wytchnienia,
      <br /> Stronki buduje jak komuś trzeba,
      <br /> Kiedyś mi przyszło IT studiować,
      <br /> Teraz jak hobby mogę traktować.
    </S.Description>
  </S.DescriptionContainer>
);
export default AboutDescription;
